import useModal from 'stores/useModal';
import { useQuery } from '@tanstack/react-query';
import { getBookingDiscount } from 'services/api/module/booking.api';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetBookingDiscount = (id: number) => {
  const { isOpenAddDiscount } = useModal();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKNIG_DISCOUNT, QUERY_KEYS.GET_BOOKING_DETAIL, id],
    queryFn: () => getBookingDiscount(Number(id)),
    refetchInterval: isOpenAddDiscount ? 3000 : false
  });

  return {
    data,
    isLoading,
    isFetching
  };
};
