import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  notification,
  Row
} from 'antd';
import useModal from 'stores/useModal';

import { useMutation } from '@tanstack/react-query';
import { buildTransactionsInvoice, updateInvoiceDetails } from 'services/api/module/booking.api';
import 'styles/post-payment-transaction.scss';
import { toLocalTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { EmailRegex } from 'constants/regex';
import { MESSAGE_CODE } from 'constants/validate';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import { useGetGuestOrderDetail } from 'hooks/useGetGuestTransactions';
import 'styles/create-invoice-modal.scss';
import { useGetTravelAgency } from 'hooks/useGetTravelAgencies';

function CreateInvoice() {
  const [form] = Form.useForm();

  const { createInvoiceTransaction, closeCreateInvoiceTransaction, setIsOpenViewPrintInvoiceV2 } =
    useModal();

  const [publishEinvoice, setPublishEinvoice] = useState(false);

  const { data: bookingLineDetail } = useGetBookingDetail(createInvoiceTransaction.booking_line_id);
  const { data: travelAgencyData } = useGetTravelAgency(bookingLineDetail?.hotel_travel_agency_id);

  const currentOrder = useMemo(() => {
    return createInvoiceTransaction.orders
      ?.filter(item => item.folio_balance_code === createInvoiceTransaction.code)
      ?.at(0);
  }, [createInvoiceTransaction]);

  const currentCustomerBookingData = useMemo(() => {
    return bookingLineDetail?.booking_line_guests
      .filter(item => item.id === currentOrder?.booking_line_guest_id)
      .at(0);
  }, [bookingLineDetail?.booking_line_guests, currentOrder?.booking_line_guest_id]);

  const { data: orderDetail } = useGetGuestOrderDetail(currentOrder ? currentOrder.id : 0);

  const { mutateAsync: mutateBuildTransactionsInvoice } = useMutation({
    mutationFn: (params: any) =>
      buildTransactionsInvoice({
        guest_order_id: params.guest_order_id,
        publish_einvoice: params.publish_einvoice,
        name: params.name,
        vat: params.vat,
        email: params.email,
        company_name: params.company_name,
        address: params.address
      })
  });

  const { mutateAsync: UpdateInvoiceDetails } = useMutation({
    mutationFn: async (values: any) => {
      const id = bookingLineDetail?.booking_line_id;
      if (id === undefined) {
        return;
      }
      const data = {
        partner_name: values.partnerName || '',
        company_name: values.company || '',
        company_address: values.companyAddress || '',
        tax_code: values.taxCode || ''
      };

      const bookingDetail = await updateInvoiceDetails(id, data);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, id]
      });
      return bookingDetail;
    }
  });

  const handleCloseModal = () => {
    form.resetFields();
    closeCreateInvoiceTransaction();
  };

  const vatRules = publishEinvoice
    ? [{ required: true, message: MESSAGE_CODE.REQUIRED_VAT_CODE }]
    : [];

  const handleShowInvoice = async () => {
    const { name, company_name, address, vat } = form.getFieldsValue();
    await UpdateInvoiceDetails({
      partnerName: name,
      company: company_name,
      companyAddress: address,
      taxCode: vat
    });
    setIsOpenViewPrintInvoiceV2(true);
  };

  const handleCreateInvoice = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (
      createInvoiceTransaction.guest_id &&
      createInvoiceTransaction.orders &&
      createInvoiceTransaction.code
    ) {
      try {
        await mutateBuildTransactionsInvoice({
          guest_order_id: currentOrder.id,
          publish_einvoice: values.publish_einvoice,
          name: values.name,
          vat: values.vat,
          email: values.email,
          company_name: values.company_name,
          address: values.address
        });
        notification.success({
          message: 'Tạo hóa đơn thành công'
        });
        closeCreateInvoiceTransaction();
        form.resetFields();
      } catch (error: any) {
        notification.error({
          message: error.error || 'Lỗi xảy ra'
        });
      } finally {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, createInvoiceTransaction.guest_id]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });
        await queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail?.booking_line_id
          ]
        });
      }
    }
  };

  useEffect(() => {
    if (travelAgencyData) {
      form.setFieldsValue({
        email: travelAgencyData.email || '',
        address: travelAgencyData.address || '',
        company_name: travelAgencyData.invoice_name || '',
        vat: travelAgencyData.vat || ''
      });
    } else {
      form.setFieldsValue({
        name: orderDetail?.guest_name || '',
        email: currentCustomerBookingData?.email || '',
        address: currentCustomerBookingData?.address || ''
      });
    }
  }, [orderDetail, form, currentCustomerBookingData, travelAgencyData]);

  return (
    <Modal
      title="Create Invoice"
      className="modal-create-invoice"
      centered
      width={750}
      open={createInvoiceTransaction.isOpenCreateInvoice}
      cancelText="Đóng"
      okButtonProps={{ className: 'btn-secondary' }}
      onOk={handleCreateInvoice}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={[
        <Button
          key="print_invoice"
          type="primary"
          className="btn-print-invoice"
          onClick={handleShowInvoice}
        >
          Print Invoice
        </Button>,
        <Button
          key="ok"
          type="primary"
          className="btn-create-invoice"
          onClick={handleCreateInvoice}
          disabled={currentOrder?.state === 'invoiced'}
        >
          Create Invoice
        </Button>,
        <Button key="cancel" className="btn-cancel" onClick={handleCloseModal}>
          Cancel
        </Button>
      ]}
    >
      <Card title="RESERVATION INFORMATION" className="reservation-info">
        <Row gutter={[16, 0]}>
          <Col span={4} className="col-bold">
            <p>Resv</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_line_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>Confirm</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>Room</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.room_name
                ? bookingLineDetail.room_name
                : bookingLineDetail?.room_type_name}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>Arrival Date</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_in
                ? toLocalTime(bookingLineDetail.check_in, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>Departure Date</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_out
                ? toLocalTime(bookingLineDetail.check_out, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          {travelAgencyData && (
            <>
              <Col span={4} className="col-bold">
                <p>Group</p>
              </Col>
              <Col span={4}>
                <p>{travelAgencyData.name}</p>
              </Col>
            </>
          )}
        </Row>
      </Card>

      <Form
        form={form}
        name="create-invoice-form"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Card title="GUEST INFORMATION" className="guest-info">
          <Row gutter={[16, 0]}>
            <Col span={6} className="label">
              <p>Export VAT E-Invoice</p>
            </Col>
            <Col span={18}>
              <Form.Item name="publish_einvoice" valuePropName="checked">
                <Checkbox onChange={e => setPublishEinvoice(e.target.checked)} />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>Guest Name</p>
            </Col>
            <Col span={20}>
              <Form.Item name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>Email</p>
            </Col>
            <Col span={20}>
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: EmailRegex,
                    message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>Company</p>
            </Col>
            <Col span={20}>
              <Form.Item name="company_name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>Address</p>
            </Col>
            <Col span={20}>
              <Form.Item name="address">
                <Input />
              </Form.Item>
            </Col>
            {publishEinvoice && (
              <>
                <Col span={4} className="label">
                  <p>
                    Tax Code <span className="required">*</span>
                  </p>
                </Col>
                <Col span={20}>
                  <Form.Item name="vat" rules={vatRules}>
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Form>
    </Modal>
  );
}

export default CreateInvoice;
