import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Upload,
  UploadProps,
  notification
} from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import API from 'services/api';

import 'styles/customer-profile.scss';
import { logEvent } from 'services/tracking/gaTracking';
import { ParamsAddDiscountImage } from 'services/api/type/booking.type';

export const LIMIT_SIZE_FILE = 2000000; // 2MB
const LIMIT_PHOTO = 1;

function AddDiscountInfo() {
  const [form] = Form.useForm();
  const { bookingLine } = useParams();
  const discount_image = Form.useWatch('discount_image', form);

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  const [loadingProcessImage, setLoadingProcessImage] = useState<boolean>(false);

  const { mutateAsync: mutateDiscountImage } = useMutation({
    mutationFn: (values: ParamsAddDiscountImage) =>
      API.booking.addDiscountImage(Number(bookingLine), values)
  });

  const fixBase64Padding = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    return base64String + padding;
  };

  useEffect(() => {
    const result: string[] = [];

    if (!_.isEmpty(discount_image)) {
      const readerPromises = discount_image.map((item: any) => {
        return new Promise<string>((resolve, reject) => {
          if (item.id) {
            result.push(item.url);
            resolve(item.url);
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(item.originFileObj);
            reader.onloadend = () => {
              let base64String = reader.result as string;
              base64String = fixBase64Padding(base64String.split(',')[1]);
              result.push(base64String);
              resolve(base64String);
            };
            reader.onerror = reject;
          }
        });
      });

      Promise.all(readerPromises)
        .then(() => {
          setPhotoUrls(result);
        })
        .catch(err => {
          console.error('Error loading images:', err);
          notification.error({
            message: 'Có lỗi xảy ra khi tải ảnh'
          });
        });
    }
  }, [discount_image]);

  const handleDeletePhoto = (index: number) => {
    const newPhotosUrls = [...photoUrls];
    newPhotosUrls.splice(index, 1);

    setPhotoUrls(newPhotosUrls);

    const photos = _.cloneDeep(form.getFieldValue('discount_image'));

    const deletedPhoto = photos.splice(index, 1);
    if (deletedPhoto[0]?.id) {
      const removeImages = _.cloneDeep(form.getFieldValue('remove_images')) || [];
      removeImages.push(deletedPhoto[0]?.id);
      form.setFieldValue('remove_images', removeImages);
    }

    form.setFieldValue('discount_image', photos);
  };

  const handleSave = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async () => {
    try {
      setLoadingProcessImage(true);
      if (photoUrls && photoUrls.length > 0) {
        await mutateDiscountImage({
          discount_image: photoUrls.at(0)
        });
      }
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    } finally {
      notification.success({
        message: 'Lưu ảnh thành công'
      });
      setLoadingProcessImage(false);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const props: UploadProps = {
    accept: 'image/png, image/jpeg, image/jpg, image/bmp',
    name: 'file',
    multiple: true,
    beforeUpload: file => {
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isJPG = ['image/jpg', 'image/jpeg'].includes(file.type);

      if (!(isPNG || isJPG || isBMP)) {
        notification.error({
          message: `${file.name} không phải định dạng jpg/jpeg/png/bmp`
        });
        return false;
      }
      logEvent({
        category: 'customer_profile',
        action: 'customer_profile.upload_picture',
        label: 'Customer Upload Photo',
        value: 1
      });
      return true;
    },
    showUploadList: false,
    disabled: discount_image?.length >= LIMIT_PHOTO
  };

  return (
    <div className="pms-qr-code">
      <Card title="Thông tin voucher" style={{ width: '100%' }}>
        <Form
          form={form}
          name="qr-code-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            discount_image: [],
            remove_images: []
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item className="m-0">
                <Input
                  defaultValue={bookingLine}
                  addonBefore={
                    <span style={{ fontWeight: 'bold', color: 'black' }}>Mã đặt phòng</span>
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="pid-box">
            <Row gutter={[16, 16]}>
              {!_.isEmpty(photoUrls) &&
                photoUrls.map((item, index) => (
                  <Col span={12} key={index}>
                    <div className="preview-item" key={index}>
                      <img src={`data:image/png;base64,${item}`} alt="thumbnail" />
                      <Button
                        icon={<DeleteFilled />}
                        className="btn-action"
                        onClick={() => handleDeletePhoto(index)}
                      />
                    </div>
                  </Col>
                ))}

              <Col span={12}>
                <Form.Item
                  name="discount_image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  className="m-0"
                >
                  <Upload.Dragger {...props} maxCount={LIMIT_PHOTO}>
                    <p className="ant-upload-drag-icon m-0">
                      <PlusOutlined /> <span>Thêm ảnh</span>
                    </p>
                    <p className="ant-upload-text">
                      {discount_image?.length}/{LIMIT_PHOTO}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item name="remove_images" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
        </Form>
      </Card>
      <div className="box-action">
        <div style={{ padding: '0 24px' }}>
          <Button
            className="ant-btn-secondary w-full"
            onClick={handleSave}
            loading={loadingProcessImage}
          >
            Lưu
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddDiscountInfo;
