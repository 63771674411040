import IconNotAssigned from 'assets/images/not-assigned.png';
import IconTask from 'assets/images/clipboard.png';

export enum FILTER_STATUS_SUB_HK_VALUE {
  ALL = 'all',
  AVAILABLE = 'available',
  ARR = 'ARR',
  FS = 'FS',
  DEP = 'DEP',
  DIRTY = 'dirty',
  LOCKED = 'locked',
  NOT_ASSIGNED = 'not_assigned',
  TASK = 'TASK',
  OD = 'OD'
}

export const FILTER_STATUS_SUB_HK = [
  {
    label: 'ALL',
    value: FILTER_STATUS_SUB_HK_VALUE.ALL,
    color: '#EFAC00',
    bgColor: '#FFF2CF'
  },
  {
    label: 'VC',
    value: FILTER_STATUS_SUB_HK_VALUE.AVAILABLE,
    color: '#42A046',
    bgColor: '#E4FBE5'
  },
  {
    label: 'VD',
    value: FILTER_STATUS_SUB_HK_VALUE.DIRTY,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  },
  {
    label: <img src={IconNotAssigned} alt="not-assigned" width={24} height={24} />,
    value: FILTER_STATUS_SUB_HK_VALUE.NOT_ASSIGNED,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  },
  {
    label: 'ARR',
    value: FILTER_STATUS_SUB_HK_VALUE.ARR,
    color: '#0782EB',
    bgColor: '#EBF6FF'
  },
  {
    label: 'DEP',
    value: FILTER_STATUS_SUB_HK_VALUE.DEP,
    color: '#9513A8',
    bgColor: '#FDECFF'
  },
  {
    label: 'OC',
    value: FILTER_STATUS_SUB_HK_VALUE.FS,
    color: '#CE370F',
    bgColor: '#FFE7E0'
  },
  {
    label: <img src={IconTask} alt="task" width={22} height={22} style={{ marginRight: 4 }} />,
    value: FILTER_STATUS_SUB_HK_VALUE.TASK,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  },
  {
    label: 'OD',
    value: FILTER_STATUS_SUB_HK_VALUE.OD,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  }
];

export const FILTER_STATUS_HK = [
  {
    label: 'ALL',
    value: FILTER_STATUS_SUB_HK_VALUE.ALL,
    color: '#EFAC00',
    bgColor: '#FFF2CF'
  },
  {
    label: 'VC',
    value: FILTER_STATUS_SUB_HK_VALUE.AVAILABLE,
    color: '#42A046',
    bgColor: '#E4FBE5'
  },
  {
    label: 'VD',
    value: FILTER_STATUS_SUB_HK_VALUE.DIRTY,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  },
  {
    label: 'ARR',
    value: FILTER_STATUS_SUB_HK_VALUE.ARR,
    color: '#0782EB',
    bgColor: '#EBF6FF'
  },
  {
    label: 'DEP',
    value: FILTER_STATUS_SUB_HK_VALUE.DEP,
    color: '#9513A8',
    bgColor: '#FDECFF'
  },
  {
    label: 'OC',
    value: FILTER_STATUS_SUB_HK_VALUE.FS,
    color: '#CE370F',
    bgColor: '#FFE7E0'
  },
  {
    label: 'OD',
    value: FILTER_STATUS_SUB_HK_VALUE.OD,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  }
];
