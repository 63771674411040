export const paths = {
  login() {
    return '/auth/login';
  },
  me() {
    return '/auth/me';
  },
  changePassword() {
    return '/users/change-password';
  },

  bookings(page?: number, limit?: number) {
    return `/bookings${page ? `?page=${page}&limit=${limit}` : ''}`;
  },
  allBookings() {
    return `/bookings`;
  },
  getPendingPostRoomCharge() {
    return `/transactions/pending-post-room-charge`;
  },
  bookingsBatchActions() {
    return `/bookings/booking-batch-actions`;
  },
  bookingDetail(id: number) {
    return `/booking-lines/${id}`;
  },
  updateInvoiceDetails(id: number) {
    return `/booking-lines/${id}/update-invoice-details`;
  },
  getAvailRoomType(startDate: string, endDate: string) {
    return `/room-types?start_date=${startDate}&end_date=${endDate}`;
  },
  actionCheckIn(id: number) {
    return `/booking-lines/${id}/checkin`;
  },
  assignCustomer() {
    return `/booking-lines/assign-customer`;
  },
  actionCheckOut(id: number) {
    return `/booking-lines/${id}/checkout`;
  },
  actionChangeDate(id: number) {
    return `/booking-lines/${id}/change-date`;
  },
  payments(id: number) {
    return `/booking-lines/${id}/payments`;
  },
  getCustomers() {
    return '/customers';
  },
  getCustomerById(customerId: number) {
    return `/customer-info/${customerId}`;
  },
  getDataCustomerOCR() {
    return '/customers-image-ocr';
  },
  getRooms() {
    return '/rooms';
  },
  getRoomStatus() {
    return '/room-status';
  },
  cleanRoom() {
    return '/room_clean';
  },
  assignRoom() {
    return '/booking-lines/assign-room';
  },
  changeRoomType() {
    return '/booking-lines/change-room-type';
  },
  exchangeRoom() {
    return '/booking-lines/exchange-room';
  },
  unassignRoom() {
    return '/booking-lines/unassign-room';
  },
  cancelBooking(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/cancel`;
  },
  updateExtraInfo(bookingLineId: number) {
    return `booking-lines/${bookingLineId}/update-extra-info`;
  },
  removeService(bookingLineId: number) {
    return `booking-lines/${bookingLineId}/remove-service`;
  },
  postRoomCharge(bookingLineId: number) {
    return `booking-lines/${bookingLineId}/post_room_charge`;
  },
  moveTransaction(guestId: number) {
    return `guests/${guestId}/move-transactions`;
  },
  postPayment(guestId: number) {
    return `guests/${guestId}/post-payment`;
  },
  postRefund(guestId: number) {
    return `guests/${guestId}/post-refund`;
  },
  transactionCodes() {
    return `/transaction_codes`;
  },
  editTransactionNote() {
    return `/guests/transactions/edit-note`;
  },
  cancelTransaction() {
    return `guests/transactions/cancel`;
  },
  cancelPayment() {
    return `/cancel-payment`;
  },
  buildTransactionsInvoice() {
    return `guests/transactions/build-transactions-invoice`;
  },
  getTransactionsPayment() {
    return `/transactions/get-transactions-payment`;
  },
  getTimeline() {
    return `/bookings/timeline`;
  },
  batchAction() {
    return `/booking-lines/batch_actions`;
  },

  getCountries() {
    return '/res/countries';
  },
  getAreas(countryId: number) {
    return `/res/states?country_id=${countryId}`;
  },
  addCustomer() {
    return '/customers';
  },
  sendEmail(bookingId: number) {
    return `/booking-confirm?booking_id=${bookingId}`;
  },
  getTracking() {
    return `/tracking-logs`;
  },
  getServices() {
    return '/services';
  },
  getBreakfasts(date: string) {
    return `/breakfasts?date=${date}`;
  },
  addServices(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/add-service`;
  },
  changePrices(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/prices`;
  },

  getCleaningCalendar() {
    return `/booking-lines/clean-calendar`;
  },

  fixRoom() {
    return '/room-lock';
  },
  unlockRoom() {
    return '/unlock-room';
  },

  getCompanies() {
    return `/utm/mediums`;
  },
  getSources() {
    return `/utm/sources`;
  },
  getStayingGuests() {
    return '/staying-customers';
  },
  getAllNationality() {
    return '/guests/nationality-statistic';
  },
  getCashFlow() {
    return '/get-cash-payments';
  },
  getTransactionsCashFlow() {
    return '/transactions/get-transactions-cash-payment';
  },
  createExpense() {
    return '/create-payment';
  },
  createExpensesCashTransactionV2() {
    return `/create-expense-cash-transaction`;
  },
  cancelExpenseCashTransactionV2() {
    return `/cancel-expense-cash-transaction`;
  },
  editCashPayment() {
    return `/edit-cash-payment`;
  },
  editExpenseCashTransaction() {
    return `/edit-expense-cash-transaction`;
  },

  getAllBranches() {
    return '/branches';
  },
  getCurrentNightAudit() {
    return '/current-branch';
  },
  finishNightAudit() {
    return '/night-audit';
  },
  autoPostRoomChargeNightAudit() {
    return '/booking-lines/auto-post-room-charge';
  },

  getRoomAvailability() {
    return `/night-audit`;
  },
  getRoomSummary() {
    return `/room-summary`;
  },
  getRoomLock() {
    return `/room-lock`;
  },

  updateBreakfast(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/booking-breakfast`;
  },
  undoCheckIn(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/undo-checkin`;
  },
  undoCheckOut(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/undo-checkout`;
  },
  getTransactions() {
    return `/get-payments`;
  },

  getAllBookingLines(bookingId?: number) {
    return `/bookings/${bookingId}/lines`;
  },
  uploadConfirmation(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/attachments`;
  },
  getReservationForecast() {
    return `/weakly-reservation-forecast`;
  },
  getHouseStatus() {
    return `/house-status`;
  },

  getSubHKRooms() {
    return `/hk/sub/rooms`;
  },
  getHKRooms() {
    return `/hk/rooms`;
  },
  shiftAssign() {
    return `/hk/shift-assign`;
  },
  getPmsUsers() {
    return `/pms-users`;
  },
  getListMinibar() {
    return `/services/minibar`;
  },
  hkCheckMinibar() {
    return `/hk/minibar-check`;
  },
  hkStartCleaning() {
    return `/hk/start-cleaning`;
  },
  hkFinishCleaning() {
    return `/hk/end-cleaning`;
  },
  subHKApproveCleaning() {
    return `/hk/sub/cleaning-approve`;
  },
  subHKFillMinibar() {
    return `/hk/sub/minibar-check`;
  },

  getCurrentBranch() {
    return `/current-branch`;
  },
  getLabels() {
    return `/labels`;
  },
  createAndAssignLabel(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/create-and-assign-label`;
  },
  unassignLabels(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/unassign-labels`;
  },
  createShiftHandover() {
    return `/handover-shifts`;
  },
  getCurrentShiftHandover() {
    return `/handover-shifts/current-shift`;
  },
  getListShiftHandover() {
    return `/handover-shifts`;
  },
  chargePaymentDebt() {
    return `/payments/pay-debt`;
  },
  scanCustomerInfo() {
    return `/customer-info-ocr`;
  },
  warehouseManagement() {
    return `/stock/products`;
  },
  getTravelAgency(id: number) {
    return `/travel-agencies/${id}`;
  },
  travelAgencies() {
    return `/travel-agencies`;
  },
  updateTravelAgency(id: number) {
    return `/travel-agencies/${id}/update`;
  },
  deleteTravelAgency(id: number) {
    return `/travel-agencies/${id}/delete`;
  },

  // Groups
  getGroupBookings() {
    return `/groups`;
  },
  getSubGroupsDetails() {
    return `/subgroups`;
  },
  getGroupBookingDetail(groupId: number) {
    return `/groups/${groupId}`;
  },
  addSubGroup(groupId: number) {
    return `/groups/${groupId}/add-subgroup`;
  },
  createGroupBooking() {
    return `/groups`;
  },
  updateGroupBooking(groupId: number) {
    return `/groups/${groupId}/update`;
  },
  cancelGroupBooking(groupId: number) {
    return `/groups/${groupId}/cancel`;
  },
  generateRooming() {
    return `/groups/rooming`;
  },
  updateSubgroup(subGroupId: number) {
    return `/subgroups/${subGroupId}`;
  },
  changeDateSubgroup(groupId: number) {
    return `/groups/${groupId}/change-date`;
  },
  cancelSubgroup(subGroupId: number) {
    return `/subgroups/${subGroupId}/cancel`;
  },
  roomsFoc() {
    return `/rooms-foc`;
  },
  servicesFoc() {
    return `/services-foc`;
  },
  updateOrCreateBookingLineGuests() {
    return `/booking-line-guest`;
  },
  getConsolidateRevenue() {
    return `/consolidate-revenue`;
  },
  getConsolidateSourceRevenue() {
    return `/consolidate-source-revenue`;
  },
  getConsolidateSourceRevenueDetails(page?: number, limit?: number) {
    return `/consolidate-source-revenue-details${page ? `?page=${page}&limit=${limit}` : ''}`;
  },
  getAddonServiceTaxes() {
    return `/addon-service-taxes`;
  },
  createDummyBooking(groupId: number) {
    return `/groups/${groupId}/dummy-booking`;
  },
  addDiscount(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/add-discount`;
  },
  addDiscountImage(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/add-discount-image`;
  },
  getBookingDiscount(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/get-booking-discount`;
  },
  getTransactionHistory() {
    return `/transaction-logs`;
  }
};
