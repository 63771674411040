import { GetConsolidateRevenueData } from 'services/api/type/report.type';

export const transformDataToTable = (data: GetConsolidateRevenueData) => {
  if (Array.isArray(data) && data.length === 0) return [];

  if (!Array.isArray(data)) {
    const transformedData = Object.keys(data).map(roomType => ({
      key: roomType,
      roomType,
      total_night: data[roomType]?.occupied?.total_night || 0,
      occupied_percent: data[roomType]?.occupied?.occupied_percent || 0,
      occupied_percent_total: data[roomType]?.occupied?.occupied_percent_total || 0,
      room_price: Math.floor(data[roomType]?.financials?.room_price || 0),
      service_cost: Math.floor(data[roomType]?.financials?.service_cost || 0),
      revenue: Math.floor(data[roomType]?.financials?.revenue || 0),
      average_price: Math.floor(data[roomType]?.financials?.average_price || 0),
      source_id: data[roomType]?.source_id
    }));

    const validEntriesCount = transformedData.filter(
      row => typeof row.occupied_percent === 'number'
    ).length;

    const totalRow = transformedData.reduce(
      (totals, row) => {
        return {
          key: 'Tổng',
          roomType: 'Tổng',
          total_night: totals.total_night + row.total_night,
          occupied_percent: totals.occupied_percent + row.occupied_percent,
          occupied_percent_total: totals.occupied_percent_total + row.occupied_percent_total,
          room_price: totals.room_price + row.room_price,
          service_cost: totals.service_cost + row.service_cost,
          revenue: totals.revenue + row.revenue,
          average_price:
            totals.total_night + row.total_night > 0
              ? Math.floor(
                  (totals.room_price + row.room_price) / (totals.total_night + row.total_night)
                )
              : 0
        };
      },
      {
        key: 'Tổng',
        roomType: 'Tổng',
        total_night: 0,
        occupied_percent: 0,
        occupied_percent_total: 0,
        room_price: 0,
        service_cost: 0,
        revenue: 0,
        average_price: 0
      }
    );

    totalRow.occupied_percent =
      validEntriesCount > 0
        ? parseFloat((totalRow.occupied_percent / validEntriesCount).toFixed(2))
        : 0;

    totalRow.occupied_percent_total = parseFloat(totalRow.occupied_percent_total.toFixed(2));

    return [...transformedData, totalRow];
  }
  return [];
};
