import { ParamsGetConsolidateRevenueType } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import _ from 'lodash';
import { PaginationType } from 'services/api/type/booking.type';
import { PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS } from 'services/api/module/report.api';

export const useGetConsolidateRevenue = (
  filterOptions: ParamsGetConsolidateRevenueType,
  isEnable: boolean = true
) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONSOLIDATE_REVENUE, filterOptions],
    queryFn: () => API.report.getConsolidateRevenue(filterOptions),
    enabled: isEnable
  });

  return {
    data: data ?? null, // Ensure `null` for unavailable data, not an empty array
    isLoading
  };
};

export const useGetConsolidateSourceRevenue = (
  filterOptions: ParamsGetConsolidateRevenueType,
  isEnable: boolean = true
) => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONSOLIDATE_SOURCE_REVENUE, filterOptions],
    queryFn: () => API.report.getConsolidateSourceRevenue(filterOptions),
    enabled: isEnable
  });
  return {
    data: data ?? null,
    isFetching,
    isLoading
  };
};

export const useGetConsolidateSourceRevenueDetails = (
  filerOptions: ParamsGetConsolidateRevenueType,
  page: number,
  pageSize: number = PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS
) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONSOLIDATE_SOURCE_REVENUE, page, filerOptions.source_id],
    queryFn: () => API.report.getConsolidateSourceRevenueDetails(filerOptions, page, pageSize),
    enabled: !!filerOptions.source_id
  });
  return {
    data: _.get(data, 'data', []),
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isLoading
  };
};
