import React from 'react';
import { Button, Form, FormProps, Input, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

import {
  hkStartCleaning,
  hkFinishCleaning,
  subHKApproveCleaning
} from 'services/api/module/hk.api';
import { SubHKRoom } from 'services/api/type/hk.type';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { CLEANING_STATUS } from 'constants/common';
import 'styles/mobile/sub-hk-inspect.scss';
interface Props {
  mainBtnText: string;
  selectedRoom?: SubHKRoom;
  isSubHK?: boolean;
  onBack?: () => void;
}

function CleaningForm({ mainBtnText, selectedRoom, isSubHK = false, onBack }: Props) {
  const [form] = Form.useForm();

  const { mutateAsync: mutateHkStartCleaning, isPending: isPendingStart } = useMutation({
    mutationFn: (shift_id: number) => {
      return hkStartCleaning(shift_id);
    }
  });

  const { mutateAsync: mutateHkFinishCleaning, isPending: isPendingFinish } = useMutation({
    mutationFn: (params: any) => {
      return hkFinishCleaning(params);
    }
  });

  const { mutateAsync: mutateSubHKApproveCleaning, isPending: isPendingApprove } = useMutation({
    mutationFn: (params: any) => {
      return subHKApproveCleaning(params);
    }
  });
  const handleStart = async () => {
    if (selectedRoom?.shift_id) {
      try {
        await mutateHkStartCleaning(selectedRoom?.shift_id);

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_HK_ROOM]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const handleEnd = async () => {
    form.submit();
  };

  const onFinishCleaning: FormProps['onFinish'] = async (values: any) => {
    if (selectedRoom?.shift_id) {
      try {
        if (isSubHK) {
          console.log('aaaaaaaa', values);
          await mutateSubHKApproveCleaning({
            shift_id: selectedRoom?.shift_id,
            note: values.note,
            sub_hk_note: values.sub_hk_note
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_SUB_HK_ROOM]
          });
        } else {
          await mutateHkFinishCleaning({ shift_id: selectedRoom?.shift_id, note: values.note });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_HK_ROOM]
          });
        }
        onBack && onBack();
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <>
      <div className="form-container">
        <p className="title">
          {selectedRoom?.cleaning_status === CLEANING_STATUS.CLEANING
            ? 'Đang vệ sinh'
            : 'Vệ sinh phòng'}
        </p>
        <Form
          form={form}
          name="sub-hk-inspect-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            note: selectedRoom?.cleaning_note || ''
          }}
          onFinish={onFinishCleaning}
          autoComplete="off"
        >
          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea rows={5} placeholder="Nội dung" readOnly={isSubHK} />
          </Form.Item>

          {isSubHK && (
            <Form.Item label="Ghi chú SubHK" name="sub_hk_note">
              <Input.TextArea rows={5} placeholder="Nội dung" />
            </Form.Item>
          )}
        </Form>

        {!isSubHK && selectedRoom?.cleaning_status !== CLEANING_STATUS.CLEANING && (
          <Button
            size="large"
            className="ant-btn-secondary w-full"
            onClick={handleStart}
            loading={isPendingStart}
            style={{ borderRadius: 4 }}
          >
            Bắt đầu làm
          </Button>
        )}
      </div>

      <div className="action-wrapper-fixed">
        <div className="inner">
          <Button
            className="ant-btn-secondary w-full"
            loading={isPendingFinish || isPendingApprove}
            disabled={!isSubHK && selectedRoom?.cleaning_status !== CLEANING_STATUS.CLEANING}
            onClick={handleEnd}
          >
            {mainBtnText}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CleaningForm;
