import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { TransactionHistoryLogs } from 'services/api/type/transaction-history-logs.type';
import { getAllTransactionHistoryLogs } from 'services/api/module/transaction-history-logs';

export const useGetTransactionHistoryLogs = (filterOptions: TransactionHistoryLogs) => {
  const { data, isLoading, isFetched, error } = useQuery({
    queryKey: [
      QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS,
      filterOptions.booking_line_order_id,
      filterOptions.start_date,
      filterOptions.end_date,
      filterOptions.type
    ],
    queryFn: () => getAllTransactionHistoryLogs(filterOptions),
    enabled: !!filterOptions.booking_line_order_id,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetched,
    error
  };
};
