import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Card, Checkbox, Empty, Radio, Skeleton } from 'antd';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';

import HeaderMobile from 'components/mobile/common/sub-hk-header';
import SubHKItem from 'components/mobile/sub-hk/sub-hk-item';
import CleaningForm from 'components/mobile/sub-hk/cleaning-form';
import MinibarForm from 'components/mobile/sub-hk/minibar-form';
import HeaderInspectMobile from 'components/mobile/common/sub-hk-header-inspect';
import ShiftDivisionForm from 'components/mobile/sub-hk/shift-division-form';
import BranchesDropdown from 'components/common/branches-dropdown';

import useModal from 'stores/useModal';
import useHKStore from 'stores/useHK';
import { useGetSubHKRoom } from 'hooks/useGetSubHKRoom';
import { useGetListMinibar } from 'hooks/useGetListMinibar';
import { cleanRoom } from 'services/api/module/room.api';

import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';
import { MiniBarItem, SubHKRoom } from 'services/api/type/hk.type';
import { FILTER_STATUS_SUB_HK, FILTER_STATUS_SUB_HK_VALUE } from 'constants/hk';
import { CLEANING_STATUS, MINIBAR_STATUS } from 'constants/common';
import 'styles/mobile/sub-hk.scss';

function SubHKMobile() {
  const { setInfoConfirmModal, setConfirmLoading } = useModal();
  const { selectedRoomIds, setSelectedRoomIds } = useHKStore();
  const [selectedRoom, setSelectedRoom] = useState<SubHKRoom>();
  const [isShowCheckMinibar, setIsShowCheckMinibar] = useState(false);
  const [isShowFillMinibar, setIsShowFillMinibar] = useState(false);
  const [isShowInspectCleaning, setIsShowInspectCleaning] = useState(false);
  const [isShowShiftDivision, setIsShowShiftDivision] = useState(false);
  const [status, setStatus] = useState(FILTER_STATUS_SUB_HK_VALUE.ALL);

  const { data: rooms, isLoading } = useGetSubHKRoom();
  const { data: minibar } = useGetListMinibar();

  const { mutateAsync: mutateDirtyRoom } = useMutation({
    mutationFn: ({ ids, is_clean }: { ids: number[]; is_clean: boolean }) => {
      return cleanRoom(ids, is_clean);
    }
  });

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };

  const handleChangeCheckBox = (values: any) => {
    setSelectedRoomIds(values);
  };

  const handleCancelSelect = () => {
    setSelectedRoomIds([]);
  };

  const handleConfirmMakeDirty = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận làm bẩn',
      onOk: () => {
        setInfoConfirmModal(false);
        handleMakeDirtyOrClean(false);
      }
    });
  };

  const handleMakeDirtyOrClean = async (isClean: boolean = false) => {
    setConfirmLoading(true);
    await mutateDirtyRoom({
      ids: selectedRoomIds,
      is_clean: isClean
    });
    setConfirmLoading(false);

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_SUB_HK_ROOM]
    });
  };

  const handleShiftDivideTaker = () => {
    window.scrollTo(0, 0);
    setIsShowShiftDivision(true);
  };

  const handleShowCheckMinibar = () => {
    setIsShowCheckMinibar(true);
  };

  const handleShowInspectCleaning = (room: SubHKRoom) => {
    setSelectedRoom(room);
    setIsShowInspectCleaning(true);
  };

  const handleShowFillMinibar = (room: SubHKRoom) => {
    setSelectedRoom(room);
    setIsShowFillMinibar(true);
  };

  const handleCancelCheckMinibar = () => {
    setIsShowCheckMinibar(false);
  };

  const handleCancelShiftDivision = () => {
    setIsShowShiftDivision(false);
  };

  const listRoomsCheckMinibar = useMemo(() => {
    return rooms.filter(room => selectedRoomIds.includes(room.room_id));
  }, [rooms, selectedRoomIds]);

  const summaryDrinksByRooms = useMemo(() => {
    const hash: any = {};
    const drinks = _.cloneDeep(listRoomsCheckMinibar).reduce(
      (cur: MiniBarItem[], next: SubHKRoom) => {
        return [...cur, ...(next.minibar_check || [])];
      },
      []
    );

    drinks.forEach(drink => {
      if (!hash[drink.product_id]) {
        hash[drink.product_id] = 0;
      }
      hash[drink.product_id] += drink.check_qty;
    });
    const uniqueDrinks = _.uniqBy(drinks, drink => drink.product_id);
    uniqueDrinks.forEach(drink => (drink.check_qty = hash[drink.product_id]));
    return uniqueDrinks;
  }, [listRoomsCheckMinibar]);

  // calculate quantity of each status and is_clean useMemo
  const quantity = useMemo(() => {
    const quantity: any = {
      [FILTER_STATUS_SUB_HK_VALUE.ALL]: rooms?.length || 0,
      [FILTER_STATUS_SUB_HK_VALUE.AVAILABLE]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.TASK]: 0,

      [FILTER_STATUS_SUB_HK_VALUE.DEP]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.FS]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.ARR]: 0,

      [FILTER_STATUS_SUB_HK_VALUE.DIRTY]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.NOT_ASSIGNED]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.OD]: 0
    };

    rooms?.forEach(item => {
      quantity[item.room_status] = (quantity[item.room_status] || 0) + 1;
      quantity[item.booked_status] = (quantity[item.booked_status] || 0) + 1;
      if (!item.is_clean) {
        quantity[FILTER_STATUS_SUB_HK_VALUE.DIRTY] += 1;
        if (item.booked_status === FILTER_STATUS_SUB_HK_VALUE.ARR) {
          quantity[FILTER_STATUS_SUB_HK_VALUE.OD] += 1;
        }
      }
      if (_.isEmpty(item.hk_assigned)) {
        quantity[FILTER_STATUS_SUB_HK_VALUE.NOT_ASSIGNED] += 1;
      }

      if (
        [CLEANING_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(
          item.cleaning_status
        ) ||
        [MINIBAR_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(item.minibar_status)
      ) {
        quantity[FILTER_STATUS_SUB_HK_VALUE.TASK] += 1;
      }
    });
    return quantity;
  }, [rooms]);

  const categorizedRooms = useMemo(() => {
    if (status === FILTER_STATUS_SUB_HK_VALUE.ALL) {
      return rooms;
    }
    if (status === FILTER_STATUS_SUB_HK_VALUE.DIRTY) {
      return rooms?.filter(item => {
        return !item.is_clean;
      });
    }
    if (status === FILTER_STATUS_SUB_HK_VALUE.NOT_ASSIGNED) {
      return rooms?.filter(item => {
        return _.isEmpty(item.hk_assigned);
      });
    }

    if (
      [
        FILTER_STATUS_SUB_HK_VALUE.DEP,
        FILTER_STATUS_SUB_HK_VALUE.FS,
        FILTER_STATUS_SUB_HK_VALUE.ARR
      ].includes(status)
    ) {
      return rooms?.filter(item => {
        return item.booked_status === status;
      });
    }

    if (status === FILTER_STATUS_SUB_HK_VALUE.TASK) {
      return rooms?.filter(item => {
        return (
          [CLEANING_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(
            item.cleaning_status
          ) ||
          [MINIBAR_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(item.minibar_status)
        );
      });
    }

    if (status === FILTER_STATUS_SUB_HK_VALUE.OD) {
      return rooms?.filter(item => {
        return !item.is_clean && item.booked_status === FILTER_STATUS_SUB_HK_VALUE.ARR;
      });
    }

    return rooms?.filter(item => {
      return item.room_status === status;
    });
  }, [rooms, status]);

  const groupDataByFloor = useMemo(() => {
    const sortedRooms = _.sortBy(categorizedRooms, 'attributes.room_no');
    const groupedData = _.groupBy(sortedRooms, item => {
      if (item.attributes?.room_no?.length === 3) {
        return item.attributes?.room_no[0];
      }
      if (item.attributes?.room_no?.length === 4) {
        return item.attributes?.room_no.slice(0, 2);
      }
      // Exception case -> error
      return item.attributes?.room_no;
    });

    return groupedData;
  }, [categorizedRooms]);

  const handleSelectAll = () => {
    setSelectedRoomIds(categorizedRooms.map(room => room.room_id));
  };

  const renderListRoom = () => {
    if (isLoading) {
      return [1, 2, 3, 4].map(item => (
        <Skeleton.Button key={item} active size="large" block style={{ height: 200 }} />
      ));
    }

    if (_.isEmpty(rooms)) {
      return (
        <Card
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 32px)'
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
        </Card>
      );
    }

    const arrayKeys = Object.keys(groupDataByFloor);

    return (
      <>
        <div className="branches">
          <BranchesDropdown />
        </div>

        <div className="pms-filter-sub-hk__room-status">
          {FILTER_STATUS_SUB_HK.slice(0, 4).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>
        <div className="pms-filter-sub-hk__room-status" style={{ marginTop: -4 }}>
          {FILTER_STATUS_SUB_HK.slice(4, 8).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>
        <div
          className="pms-filter-sub-hk__room-status"
          style={{ marginTop: -4, marginBottom: 12, maxWidth: '26%' }}
        >
          {FILTER_STATUS_SUB_HK.slice(8).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>

        <Checkbox.Group value={selectedRoomIds} onChange={handleChangeCheckBox}>
          {arrayKeys.map((key, index) => {
            const data = groupDataByFloor[key];

            return (
              <div key={index} className="ant-checkbox-group__inner">
                <p className="floor-text">Tầng {key}</p>
                {!_.isEmpty(data) &&
                  data.map((item, indexRoom) => (
                    <Checkbox value={item.room_id} key={indexRoom}>
                      <SubHKItem
                        data={item}
                        onShowInspectCleaning={() => handleShowInspectCleaning(item)}
                        onFillMinibar={() => handleShowFillMinibar(item)}
                      />
                    </Checkbox>
                  ))}
              </div>
            );
          })}
        </Checkbox.Group>
      </>
    );
  };

  const render = () => {
    if (isShowCheckMinibar) {
      return (
        <div className="pms-checking-minibar">
          <div className="pms-drink-detail">
            <div className="flex items-center flex-wrap" style={{ gap: 4 }}>
              Danh sách phòng:{' '}
              {listRoomsCheckMinibar.map((room, index) => (
                <b key={room.room_id}>
                  {room.room_name}
                  {index !== listRoomsCheckMinibar.length - 1 ? ',' : ''}
                </b>
              ))}
            </div>
            {!!summaryDrinksByRooms.length &&
              summaryDrinksByRooms.map(item => (
                <div className="pms-drink-detail__item" key={item.product_id}>
                  <div className="pms-drink-detail__item-drink">{item.product_name}</div>
                  <span>{item.check_qty}</span>
                </div>
              ))}
          </div>
        </div>
      );
    }
    return renderListRoom();
  };

  if (isShowInspectCleaning) {
    return (
      <div className="pms-sub-hk-inspect">
        <HeaderInspectMobile
          selectedRoom={selectedRoom}
          onBack={() => setIsShowInspectCleaning(false)}
        />
        <CleaningForm
          isSubHK
          selectedRoom={selectedRoom}
          onBack={() => setIsShowInspectCleaning(false)}
          mainBtnText="Đã Kiểm Duyệt"
        />
      </div>
    );
  }

  if (isShowFillMinibar) {
    return (
      <div className="pms-sub-hk-fill-minibar">
        <HeaderInspectMobile
          selectedRoom={selectedRoom}
          onBack={() => setIsShowFillMinibar(false)}
        />
        <MinibarForm
          isSubHK
          minibar={minibar}
          selectedRoom={selectedRoom}
          onBack={() => setIsShowFillMinibar(false)}
          mainBtnText="Đã kiểm duyệt"
        />
      </div>
    );
  }

  if (isShowShiftDivision) {
    return (
      <div className="pms-sub-hk-inspect">
        <HeaderMobile
          selectedRooms={selectedRoomIds}
          isShowCheckMinibar={isShowCheckMinibar}
          isShowShiftDivision={isShowShiftDivision}
          onCancelSelect={handleCancelSelect}
          onBack={handleCancelShiftDivision}
        />
        <ShiftDivisionForm
          selectedRooms={listRoomsCheckMinibar}
          onBack={handleCancelShiftDivision}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames('pms-sub-hk', {
        'pms-sub-hk--with-main-actions': !!selectedRoomIds.length,
        'pms-sub-hk--minibar': isShowCheckMinibar
      })}
    >
      <HeaderMobile
        selectedRooms={selectedRoomIds}
        isShowCheckMinibar={isShowCheckMinibar}
        onCancelSelect={handleCancelSelect}
        onBack={handleCancelCheckMinibar}
        onSelectAll={handleSelectAll}
      />

      {render()}

      {!!selectedRoomIds.length && !isShowCheckMinibar && (
        <div className="action-sub-hk-wrapper-fixed">
          <div className="inner">
            <Button className="ant-btn-secondary flex-grow" onClick={handleConfirmMakeDirty}>
              Làm bẩn
            </Button>
            <Button className="ant-btn-fill-bar flex-grow" onClick={handleShiftDivideTaker}>
              Phân ca
            </Button>
            <Button className="flex-grow" type="primary" onClick={handleShowCheckMinibar}>
              Check Minibar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubHKMobile;
