import React from 'react';
import _ from 'lodash';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { SubHKRoom } from 'services/api/type/hk.type';
import { CLEANING_STATUS, MINIBAR_STATUS } from 'constants/common';
import { ROOM_STATUS } from 'constants/form';
import { ReactComponent as IconDirty } from 'assets/images/ic_house_keeping_dirty_no_fill.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { ReactComponent as IconWine } from 'assets/images/wine.svg';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import { toLocalTime } from 'utils';
import IconArrival from 'assets/images/ic_arrival.svg';
import IconDeparture from 'assets/images/ic_departure.svg';

interface Props {
  data: SubHKRoom;
  onShowInspectCleaning?: () => void;
  onFillMinibar?: () => void;
}

function HKItem({ data, onShowInspectCleaning, onFillMinibar }: Props) {
  const handleInspect = () => {
    onShowInspectCleaning && onShowInspectCleaning();
  };

  const handleFillMinibar = () => {
    onFillMinibar && onFillMinibar();
  };

  const getTextMinibar = () => {
    return data.minibar_check?.map(item => `${item.check_qty} ${item.product_name}`).join(', ');
  };

  return (
    <div className="pms-hk-item">
      <div className="pms-hk-item__head">
        <div className="flex items-center" style={{ gap: 4 }}>
          <span className="pms-hk-item__room">
            {data.room_type_name} {data.attributes.room_no}
          </span>
          <div className="flex items-center" style={{ gap: 8 }}>
            {data.is_clean === false && (
              <div className="pms-sub-hk-item__status dirty">
                <IconDirty style={{ width: 20, height: 20 }} />
              </div>
            )}
            {data.room_status === ROOM_STATUS.LOCKED.toLowerCase() && (
              <div className="pms-sub-hk-item__status locked">
                <IconFix />
              </div>
            )}
            {data.room_status === 'booked' && (
              <div className="pms-sub-hk-item__status checkin">
                <IconBedUser style={{ width: 20, height: 20, fill: 'red' }} /> ({data.booked_status}
                )
              </div>
            )}

            {(!_.isEmpty(data.guest_booking_info) ||
              !_.isEmpty(data.cleaning_sub_note) ||
              !_.isEmpty(data.minibar_sub_note)) && (
              <Tooltip
                title={
                  <div>
                    <p className="m-0">{data.guest_booking_info?.partner_name || ''}</p>
                    <div className="pms-room-item__tooltip-header__timeline">
                      {data.guest_booking_info?.check_in && (
                        <div className="flex items-center" style={{ gap: '4px' }}>
                          <img src={IconArrival} alt="arrival" />
                          <span>
                            {toLocalTime(data.guest_booking_info?.check_in, 'DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                      )}
                      {data.guest_booking_info?.check_out && (
                        <div className="flex items-center" style={{ gap: '4px' }}>
                          <img src={IconDeparture} alt="departure" />
                          <span>
                            {toLocalTime(data.guest_booking_info?.check_out, 'DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                      )}
                      {data.cleaning_sub_note && (
                        <p className="cleaning-note">Ghi chú dọn dẹp: {data.cleaning_sub_note}</p>
                      )}
                      {data.minibar_sub_note && (
                        <p className="minibar-note">Ghi chú minibar: {data.minibar_sub_note}</p>
                      )}
                    </div>
                  </div>
                }
                arrow={false}
              >
                <InfoCircleOutlined onClick={e => e.stopPropagation()} className="info-tooltip" />
              </Tooltip>
            )}
          </div>
        </div>
        {data.room_status === 'booked' && (
          <div className="pms-hk-item__status checkin">
            <span>Khách ở ({data.booked_status})</span>
          </div>
        )}
      </div>

      <div className="pms-hk-item__body">
        {!_.isEmpty(data.minibar_check) && (
          <div className="pms-sub-hk-item__body">
            <div className="flex items-center" style={{ gap: 8 }}>
              <div className="pms-sub-hk-item__status fill">
                <IconWine style={{ width: 24, height: 24 }} />
              </div>
              <span className="pms-sub-hk-item__status-minibar">({getTextMinibar()})</span>
            </div>
          </div>
        )}
      </div>

      <div className="pms-hk-item_footer">
        <Button
          type="primary"
          onClick={handleFillMinibar}
          style={{ borderRadius: 4 }}
          disabled={
            !_.isEmpty(data.minibar_check) || data.minibar_status === MINIBAR_STATUS.APPROVE
          }
        >
          Minibar
        </Button>
        <Button
          className="ant-btn-secondary-mobile"
          onClick={handleInspect}
          disabled={[CLEANING_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(
            data.cleaning_status
          )}
        >
          {data.cleaning_status === CLEANING_STATUS.CLEANING ? 'Đang vệ sinh' : 'Vệ sinh phòng'}
        </Button>
      </div>
    </div>
  );
}

export default HKItem;
