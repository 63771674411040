import React, { useEffect, useMemo, useState } from 'react';
import { Form, notification } from 'antd';
import useModal from 'stores/useModal';

import dayjs, { Dayjs } from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { createDummyBooking, postPayment } from 'services/api/module/booking.api';
import { useGetTransactionCodes } from 'hooks/useGetGuestTransactions';
import { RenderPostTransactionModal } from 'components/modal/transaction-modal/utils/renderPostTransactionModal';
import { useGetMe } from 'hooks/useGetMe';

function PostDummyRoomModal() {
  const [form] = Form.useForm();

  const {
    postDummyRoomCharge: { isOpenPostDummyRoomCharge, dataGroup },
    closePostDummyRoomCharge
  } = useModal();

  const [groupId, setGroupId] = useState<number>(0);
  const [checkIn, setCheckIn] = useState<string>('');
  const [checkOut, setCheckOut] = useState<string>('');
  const { data: transactionCodes } = useGetTransactionCodes();
  const { data: userData } = useGetMe();

  const uniqueTransactionCodesName = new Set([
    transactionCodes
      ?.filter(item => Number(item.sub_code) > 0 && item.transaction_group === 'payment')
      .map(item => item.name)
  ]);

  const { mutateAsync: mutatePostDummyBooking, isPending } = useMutation({
    mutationFn: async (params: any) => {
      const guestId = await createDummyBooking({
        group_id: groupId,
        check_in: checkIn,
        check_out: checkOut
      });
      return postPayment({
        guest_id: Number(guestId.guest_id),
        folio_balance_code: params.folio_balance_code,
        note: params.note,
        transaction_code_id: params.transaction_code_id,
        amount: params.amount,
        transaction_date: dayjs(params.transaction_date).format('YYYY-MM-DD')
      });
    }
  });
  const dummyBalance = useMemo(() => {
    return (
      dataGroup?.sub_groups
        .filter(item => item.dummy_booking)
        ?.reduce((cur, next) => {
          const balanceSum = next.dummy_booking.balance_dummy_booking.reduce(
            (sum, num) => sum + num,
            0
          );
          return cur + balanceSum;
        }, 0) || 0
    );
  }, [dataGroup]);

  useEffect(() => {
    if (dataGroup) {
      const groupId = dataGroup.id;
      const checkInDate = dataGroup.first_arrival;
      const checkOutDate = dataGroup.last_departure;
      setGroupId(Number(groupId));
      setCheckIn(checkInDate);
      setCheckOut(checkOutDate);
    }
  }, [dataGroup]);

  useEffect(() => {
    if (isOpenPostDummyRoomCharge) {
      form.setFieldValue('folio_balance_code', 'A');
    }
  }, [isOpenPostDummyRoomCharge, form]);

  const handleCloseModal = () => {
    closePostDummyRoomCharge();
  };

  const onFinish: (values: {
    from_date: Dayjs;
    to_date: Dayjs;
    folio_balance_code: string;
    post_option: string;
    amount?: number;
  }) => Promise<void> = async values => {
    try {
      await mutatePostDummyBooking(values);
      closePostDummyRoomCharge();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(dataGroup?.id)]
      });
      notification.success({
        message: 'Thêm tiền đặc cọc thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <RenderPostTransactionModal
      title="DEPOSIT AMOUNT"
      isOpen={isOpenPostDummyRoomCharge}
      handleClose={handleCloseModal}
      form={form}
      onFinish={onFinish}
      isPending={isPending}
      userData={userData}
      transactionCodes={transactionCodes}
      uniqueTransactionCodesName={uniqueTransactionCodesName}
      dummyBalance={Number(dummyBalance)}
    />
  );
}

export default PostDummyRoomModal;
