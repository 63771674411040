import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton, Empty, Card, Radio } from 'antd';
import classNames from 'classnames';

import CleaningForm from 'components/mobile/sub-hk/cleaning-form';
import MinibarForm from 'components/mobile/sub-hk/minibar-form';
import HeaderInspectMobile from 'components/mobile/common/sub-hk-header-inspect';
import HeaderMobile from 'components/mobile/common/hk-header';
import HKItem from 'components/mobile/sub-hk/hk-item';

import { useGetHKRoom } from 'hooks/useGetHKRoom';
import { useGetListMinibar } from 'hooks/useGetListMinibar';
import { SubHKRoom } from 'services/api/type/hk.type';
import { FILTER_STATUS_HK, FILTER_STATUS_SUB_HK_VALUE } from 'constants/hk';
import { CLEANING_STATUS, MINIBAR_STATUS } from 'constants/common';
import 'styles/mobile/hk.scss';

function HKMobile() {
  const [selectedRoom, setSelectedRoom] = useState<SubHKRoom>();
  const [isShowFillMinibar, setIsShowFillMinibar] = useState(false);
  const [isShowInspectCleaning, setIsShowInspectCleaning] = useState(false);
  const [status, setStatus] = useState(FILTER_STATUS_SUB_HK_VALUE.ALL);

  const { data: rooms, isLoading } = useGetHKRoom();
  const { data: minibar } = useGetListMinibar();

  useEffect(() => {
    if (!_.isEmpty(selectedRoom)) {
      setSelectedRoom(rooms.find(room => room.room_id === selectedRoom.room_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  const handleShowInspectCleaning = (room: any) => {
    setSelectedRoom(room);
    setIsShowInspectCleaning(true);
  };

  const handleShowFillMinibar = (room: any) => {
    setSelectedRoom(room);
    setIsShowFillMinibar(true);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };

  // calculate quantity of each status and is_clean useMemo
  const quantity = useMemo(() => {
    const quantity: any = {
      [FILTER_STATUS_SUB_HK_VALUE.ALL]: rooms?.length || 0,
      [FILTER_STATUS_SUB_HK_VALUE.AVAILABLE]: 0,

      [FILTER_STATUS_SUB_HK_VALUE.DEP]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.FS]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.ARR]: 0,

      [FILTER_STATUS_SUB_HK_VALUE.DIRTY]: 0,
      [FILTER_STATUS_SUB_HK_VALUE.OD]: 0
    };

    rooms?.forEach(item => {
      quantity[item.room_status] = (quantity[item.room_status] || 0) + 1;
      quantity[item.booked_status] = (quantity[item.booked_status] || 0) + 1;
      if (!item.is_clean) {
        quantity[FILTER_STATUS_SUB_HK_VALUE.DIRTY] += 1;
        if (item.booked_status === FILTER_STATUS_SUB_HK_VALUE.ARR) {
          quantity[FILTER_STATUS_SUB_HK_VALUE.OD] += 1;
        }
      }
    });
    return quantity;
  }, [rooms]);

  const categorizedRooms = useMemo(() => {
    if (status === FILTER_STATUS_SUB_HK_VALUE.ALL) {
      return rooms;
    }
    if (status === FILTER_STATUS_SUB_HK_VALUE.DIRTY) {
      return rooms?.filter(item => {
        return !item.is_clean;
      });
    }
    if (status === FILTER_STATUS_SUB_HK_VALUE.NOT_ASSIGNED) {
      return rooms?.filter(item => {
        return _.isEmpty(item.hk_assigned);
      });
    }

    if (
      [
        FILTER_STATUS_SUB_HK_VALUE.DEP,
        FILTER_STATUS_SUB_HK_VALUE.FS,
        FILTER_STATUS_SUB_HK_VALUE.ARR
      ].includes(status)
    ) {
      return rooms?.filter(item => {
        return item.booked_status === status;
      });
    }

    if (status === FILTER_STATUS_SUB_HK_VALUE.TASK) {
      return rooms?.filter(item => {
        return (
          [CLEANING_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(
            item.cleaning_status
          ) ||
          [MINIBAR_STATUS.WAIT_FOR_APPROVAL, CLEANING_STATUS.APPROVE].includes(item.minibar_status)
        );
      });
    }

    if (status === FILTER_STATUS_SUB_HK_VALUE.OD) {
      return rooms?.filter(item => {
        return !item.is_clean && item.booked_status === FILTER_STATUS_SUB_HK_VALUE.ARR;
      });
    }

    return rooms?.filter(item => {
      return item.room_status === status;
    });
  }, [rooms, status]);

  const groupDataByFloor = useMemo(() => {
    const sortedRooms = _.sortBy(categorizedRooms, 'attributes.room_no');
    const groupedData = _.groupBy(sortedRooms, item => {
      if (item.attributes?.room_no?.length === 3) {
        return item.attributes?.room_no[0];
      }
      if (item.attributes?.room_no?.length === 4) {
        return item.attributes?.room_no.slice(0, 2);
      }
      // Exception case -> error
      return item.attributes?.room_no;
    });

    return groupedData;
  }, [categorizedRooms]);

  const renderListRoom = () => {
    if (isLoading) {
      return [1, 2, 3, 4].map(item => (
        <Skeleton.Button key={item} active size="large" block style={{ height: 200 }} />
      ));
    }
    if (_.isEmpty(rooms)) {
      return (
        <Card
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 32px)'
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
        </Card>
      );
    }

    const arrayKeys = Object.keys(groupDataByFloor);

    return (
      <>
        <div className="pms-filter-hk__room-status">
          {FILTER_STATUS_HK.slice(0, 3).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>
        <div className="pms-filter-hk__room-status" style={{ marginTop: -4 }}>
          {FILTER_STATUS_HK.slice(3, 6).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>
        <div
          className="pms-filter-hk__room-status"
          style={{ marginTop: -4, marginBottom: 12, maxWidth: '34.5%' }}
        >
          {FILTER_STATUS_HK.slice(6).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.value}
              style={{
                color: item.color,
                backgroundColor: item.value === status ? item.bgColor : ''
              }}
              checked={item.value === status}
              onChange={handleChangeStatus}
            >
              {item.label} ({quantity[item.value] || 0})
            </Radio.Button>
          ))}
        </div>

        <div className="room-group">
          {arrayKeys.map((key, index) => {
            const data = groupDataByFloor[key];

            return (
              <div key={index}>
                <p className="floor-text">Tầng {key}</p>
                <div className="floor-group">
                  {!_.isEmpty(data) &&
                    data.map((item, indexRoom) => (
                      <HKItem
                        data={item}
                        key={indexRoom}
                        onShowInspectCleaning={() => handleShowInspectCleaning(item)}
                        onFillMinibar={() => handleShowFillMinibar(item)}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  if (isShowInspectCleaning) {
    return (
      <div className="pms-sub-hk-inspect">
        <HeaderInspectMobile
          selectedRoom={selectedRoom}
          onBack={() => setIsShowInspectCleaning(false)}
        />
        <CleaningForm
          selectedRoom={selectedRoom}
          onBack={() => setIsShowInspectCleaning(false)}
          mainBtnText="Hoàn thành"
        />
      </div>
    );
  }

  if (isShowFillMinibar) {
    return (
      <div className="pms-sub-hk-fill-minibar">
        <HeaderInspectMobile
          selectedRoom={selectedRoom}
          onBack={() => setIsShowFillMinibar(false)}
        />
        <MinibarForm
          selectedRoom={selectedRoom}
          minibar={minibar}
          mainBtnText="Hoàn thành"
          onBack={() => setIsShowFillMinibar(false)}
        />
      </div>
    );
  }

  return (
    <div className={classNames('pms-hk')}>
      <HeaderMobile />

      {renderListRoom()}
    </div>
  );
}

export default HKMobile;
