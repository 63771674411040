import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Col, DatePicker, Form, FormProps, Modal, notification, Radio, Row, Select } from 'antd';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import useModal from 'stores/useModal';

import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { BookingStatus, FOLIO_BALANCE_CODE } from 'services/api/type/booking.type';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { postRoomCharge } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

function PostRoomChargeModal() {
  const [form] = Form.useForm();

  const {
    postRoomCharge: { isOpenPostRoomCharge, bookingLineId },
    closePostRoomCharge
  } = useModal();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { mutateAsync: mutatePostRoomCharge, isPending } = useMutation({
    mutationFn: (params: any = {}) =>
      postRoomCharge({
        id: Number(bookingLineId),
        from_date: params.from_date,
        to_date: params.to_date,
        folio_balance_code: params.folio_balance_code,
        post_option: params.post_option
      })
  });

  useEffect(() => {
    if (bookingLineId) {
      form.resetFields();
    }
  }, [form, bookingLineId]);

  const handleCloseModal = () => {
    closePostRoomCharge();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutatePostRoomCharge({
        from_date: values.from_date.format('YYYY-MM-DD'),
        to_date: values.to_date.format('YYYY-MM-DD'),
        folio_balance_code: values.folio_balance_code,
        post_option: values.post_option
      });
      closePostRoomCharge();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const minDate = useMemo(
    () => (bookingLineDetail?.check_in ? dayjs(bookingLineDetail?.check_in) : undefined),
    [bookingLineDetail?.check_in]
  );

  const maxDate = useMemo(
    () =>
      bookingLineDetail?.check_out
        ? dayjs(bookingLineDetail?.check_out).subtract(1, 'day')
        : undefined,
    [bookingLineDetail?.check_out]
  );

  return (
    <Modal
      title={
        <div className="modal-change-price-header">
          <h3 className="modal-change-price-header__title">Post Room Charge</h3>
          <div className="modal-change-price-header__info">
            <div className="flex items-center" style={{ gap: '4px' }}>
              {bookingLineDetail?.status === BookingStatus.CHECK_IN ? (
                <IconBedUser />
              ) : (
                <IconCancelCalendar />
              )}
              <span style={{ lineHeight: 'normal' }}>
                {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
              </span>
            </div>

            <p className="modal-change-price-header__customer-name">
              {bookingLineDetail?.partner_name || 'Guest'}
            </p>
          </div>
        </div>
      }
      centered
      width={500}
      open={isOpenPostRoomCharge}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      closeIcon={null}
      className={classNames('modal-change-price')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-price-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          from_date: dayjs(),
          to_date: dayjs(),
          folio_balance_code: 'A',
          post_option: 'single'
        }}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={(changedValues: any) => {
          if (changedValues.from_date) {
            const fromDate = changedValues.from_date;
            const currentToDate = form.getFieldValue('to_date');

            if (currentToDate && fromDate.isAfter(currentToDate)) {
              form.setFieldsValue({ to_date: fromDate });
            }
          }
        }}
      >
        <div className="change-price-col">
          <div className="change-price-preset">
            <Row>
              <Col span={12} className="flex items-center">
                <b>
                  <span>Transaction Page</span>
                </b>
              </Col>
              <Col span={12}>
                <Form.Item name="folio_balance_code" className="m-0 w-full">
                  <Select options={FOLIO_BALANCE_CODE} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={12} className="flex items-center">
                <Form.Item
                  label="From date"
                  name="from_date"
                  rules={[
                    {
                      validator: async (_, value) => {
                        const toDate = form.getFieldValue('to_date');
                        if (toDate && value.isAfter(toDate)) {
                          throw new Error('From date must be before or equal to To date');
                        }
                      }
                    }
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="w-full"
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="To date" name="to_date" className="m-0 flex-grow">
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    format="DD/MM/YYYY"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Post options:" name="post_option">
                  <Radio.Group buttonStyle="outline" className="flex flex-col">
                    <Radio value="single">Post only this booking</Radio>
                    <Radio value="party" disabled={!bookingLineDetail?.has_party}>
                      Post all bookings from this party #{bookingLineDetail?.booking_id}
                    </Radio>
                    <Radio value="group" disabled={!bookingLineDetail?.has_group}>
                      Post all group bookings to this booking #{bookingLineDetail?.group?.name}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default PostRoomChargeModal;
