import _ from 'lodash';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { PaymentItem } from './invoice-table';

const borderColor = '#000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 28
  },
  date: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px'
  },
  detail: {
    width: '30%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px'
  },
  price: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  qty: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  serviceFee: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  tax: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  amount: {
    width: '15%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  text: { fontSize: 11, fontFamily: 'Inter', fontWeight: 400 },
  textNumber: { fontSize: 10, fontFamily: 'Inter', fontWeight: 400 }
});

interface Props {
  items: PaymentItem[];
  listTax: {
    [key: number]: boolean;
  };
  forGroup?: boolean;
  groupItems?: any[];
}

const InvoiceTableRow = ({ items, listTax, forGroup, groupItems }: Props) => {
  let rows: any[];
  if (forGroup) {
    rows =
      groupItems?.map((item, index: number) => (
        <View style={styles.row} key={index}>
          <View style={styles.detail}>
            <Text style={styles.text}>{item.room_type_name}</Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.textNumber}>{item.check_in}</Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.textNumber}>{item.check_out}</Text>
          </View>
          <View style={styles.qty}>
            <Text style={styles.text}>{item.quantity}</Text>
          </View>
          <View style={styles.qty}>
            <Text style={styles.textNumber}>{item.pax}</Text>
          </View>
          <View style={styles.qty}>
            <Text style={styles.textNumber}>{item.count_date}</Text>
          </View>
          <View style={styles.price}>
            <Text style={styles.textNumber}>{item.room_price.toLocaleString('vn')}</Text>
          </View>
          <View style={styles.price}>
            <Text style={styles.textNumber}>{item.total_price.toLocaleString('vn')}</Text>
          </View>
        </View>
      )) || [];
  } else {
    rows = items.map((item, index: number) => (
      <View style={styles.row} key={index}>
        <View style={styles.date}>
          <Text style={styles.text}>{item.date}</Text>
        </View>
        <View style={styles.detail}>
          <Text style={styles.text}>{item.detail}</Text>
        </View>
        <View style={styles.price}>
          <Text style={styles.textNumber}>{item.price}</Text>
        </View>
        <View style={styles.qty}>
          <Text style={styles.textNumber}>{item.qty}</Text>
        </View>
        <View style={styles.serviceFee}>
          <Text style={styles.textNumber}>{item.serviceFee}</Text>
        </View>
        {Object.keys(listTax).map((tax, index) => (
          <View style={styles.tax} key={index}>
            <Text style={styles.textNumber}>{_.get(item, `tax-${tax}`, '')}</Text>
          </View>
        ))}
        <View style={styles.amount}>
          <Text style={styles.textNumber}>{item.amount}</Text>
        </View>
      </View>
    ));
  }
  return <>{rows}</>;
};

export default InvoiceTableRow;
