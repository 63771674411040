import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { BranchesType } from '@/services/api/type/branch.type';

const borderColor = '#000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 48
  },
  date: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px'
  },
  detail: {
    width: '21%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px'
  },
  nlte: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 4px'
  },
  price: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  services: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  serviceFee: {
    width: '12%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  tax: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  amount: {
    width: '12%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  text: { fontSize: 11, fontFamily: 'Inter', fontWeight: 400 },
  textNumber: { fontSize: 10, fontFamily: 'Inter', fontWeight: 400 }
});

interface Props {
  bookingLineItems: any[];
  currentBranch: BranchesType | undefined;
}

const InvoiceTableRow = ({ bookingLineItems, currentBranch }: Props) => {
  const formatNumber = (textNumber: number) => {
    return textNumber.toLocaleString('vn');
  };

  const rows = bookingLineItems.map((item, index: number) => (
    <View style={styles.row} key={index}>
      <View style={styles.detail}>
        <Text style={styles.text}>{item.room_name}</Text>
        <Text style={styles.text}>{item.partner_name}</Text>
      </View>
      <View style={styles.date}>
        <Text style={styles.text}>{item.check_in}</Text>
        <Text style={styles.text}>{item.check_out}</Text>
      </View>
      <View style={styles.nlte}>
        <Text style={styles.textNumber}>{item.nlte}</Text>
      </View>
      <View style={styles.price}>
        <Text style={styles.textNumber}>{formatNumber(item.subAmount)}</Text>
      </View>
      <View style={styles.services}>
        <Text style={styles.textNumber}>{formatNumber(item.totalService)}</Text>
      </View>
      <View style={styles.serviceFee}>
        <Text style={styles.textNumber}>{formatNumber(item.serviceFee)}</Text>
      </View>
      <View style={styles.tax}>
        <Text style={styles.textNumber}>{formatNumber(item.tax)}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={styles.textNumber}>{formatNumber(item.total_price)}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={styles.textNumber}>
          {formatNumber(
            currentBranch?.is_enable_v2 ? item.total_price - item.remain_amount : item.paid_amount
          )}
        </Text>
      </View>
      <View style={{ ...styles.amount, borderRightWidth: 0 }}>
        <Text style={styles.textNumber}>
          {formatNumber(
            currentBranch?.is_enable_v2 ? item.remain_amount : item.total_price - item.paid_amount
          )}
        </Text>
      </View>
    </View>
  ));
  return <>{rows}</>;
};

export default InvoiceTableRow;
