import CustomerTableForConsolidationRevenue from './CustomerTableForConsolidateRevenue';
import { useGetConsolidateSourceRevenue } from 'hooks/uesGetConsolidateRevenue';
import { transformDataToTable } from './utils';
import { useMemo } from 'react';
import { GetConsolidateRevenueData, TransformedRevenueData } from 'services/api/type/report.type';

interface UsedRoomsFiltersProps {
  filterOptions: any;
  isEnable: boolean;
  setDataSource: (data: any[]) => void;
}

export default function SourceTab({
  filterOptions,
  isEnable,
  setDataSource
}: UsedRoomsFiltersProps) {
  const { data: consolidateRevenue, isLoading } = useGetConsolidateSourceRevenue(
    filterOptions,
    isEnable
  );

  const dataSource = useMemo(() => {
    if (consolidateRevenue) {
      return transformDataToTable(
        consolidateRevenue as unknown as GetConsolidateRevenueData
      ) as TransformedRevenueData[];
    }
    return [];
  }, [consolidateRevenue]);

  setDataSource(dataSource);

  return (
    <CustomerTableForConsolidationRevenue
      loading={isLoading}
      dataSource={dataSource}
      title="Nguồn"
      filterOptions={filterOptions}
    />
  );
}
