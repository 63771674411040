import { axiosInstance } from 'services/api/base.api';
import { paths } from 'services/api/paths';
import { TransactionHistoryLogs } from 'services/api/type/transaction-history-logs.type';

export const getAllTransactionHistoryLogs = async (
  params: TransactionHistoryLogs
): Promise<any> => {
  return axiosInstance
    .get(paths.getTransactionHistory(), { params })
    .then(response => response.data.data);
};
