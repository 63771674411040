import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, FormProps, InputNumber, Modal, notification } from 'antd';
import {
  CloseCircleFilled,
  CloudUploadOutlined,
  IdcardOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { CustomerForm } from '../../form/customer-info/CustomerForm';
import IconUser from 'assets/images/ic_note_adults.svg';
import IconChildren from 'assets/images/ic_note_children.svg';
import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';
import { ReactComponent as IconRegisterCard } from 'assets/images/register-card.svg';

import { SEX } from 'constants/form';
import { queryClient } from 'index';
import { updateOrCreateBookingLineGuests } from 'services/api/module/customer.api';
import QUERY_KEYS from 'services/api/queryKeys';
import { getBase64, trimTruthyValues } from 'utils';
import { useUpdateCustomers } from './useUpdateCustomers';
import PAGES from 'routes/constants';
import { useLocation } from 'react-router-dom';

function CustomerModal() {
  const [form] = Form.useForm();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });
  const location = useLocation();

  const customersForm: any[] = Form.useWatch('customers', form);
  const { isOpenCustomer, setIsOpenCustomer } = useModal();
  useUpdateCustomers(form, customersForm);
  const {
    bookingLineDetail,
    selectedCustomerId: selectedCustomerIdStore,
    setSelectedCustomerId: setSelectedCustomerIdStore
  } = useBookingStore();
  const [isImageScanned, setIsImagesScanned] = useState<string | null>(null);

  const updateFormFields = (updatedInfo: any) => {
    const selectedCustomer = updatedInfo.customers[selectedCustomerId];
    setIsImagesScanned(selectedCustomer.images);
  };

  const generateImageScanned = Array.isArray(isImageScanned)
    ? isImageScanned.filter(item => item)
    : [isImageScanned].filter(item => item);

  const { mutateAsync: createOrUpdateCustomer, isPending } = useMutation({
    mutationFn: async (values: any) => {
      const generateImageImport = values.customers.attachments
        ? await Promise.all(
            values.customers.attachments
              .filter((photo: any) => _.isNil(photo.id))
              .map(async (photo: any) => getBase64(photo.originFileObj))
          )
        : undefined;

      const data = await Promise.all(
        values.customers.map(async (item: any, index: number) => ({
          profile_id: item.profile_id,
          dob: item.dob?.format('YYYY-MM-DD'),
          name: item.name,
          gender: item.gender,
          phone: item.phone,
          email: item.email,
          vat: item.vat,
          identification: item.identification,
          street: item.street,
          state_id: item.state_id,
          country_id: item.country_id,
          comment: item.comment ? item.comment : '',
          images:
            selectedCustomerId === index ? generateImageScanned || generateImageImport : undefined,
          remove_images: item.remove_images,
          guest: item.guest ? item.guest : undefined
        }))
      );
      await updateOrCreateBookingLineGuests({
        booking_line_id: bookingLineDetail?.booking_line_id,
        profiles: data,
        adult: values.adult,
        child: values.child
      });
      // return assignCustomer({
      //   booking_line_id: bookingLineDetail?.booking_line_id,
      //   customer_ids: customers.map(item => item.id),
      //   adult: values.adult,
      //   child: values.child
      // });
    }
  });

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | number>(0);
  const [isShowUpload, setIsShowUpload] = useState(false);

  useEffect(() => {
    if (!isOpenCustomer) {
      form.resetFields();
      setSelectedCustomerId(0);
      setSelectedCustomerIdStore(undefined);
    } else {
      form.setFieldsValue({
        adult: bookingLineDetail?.adult,
        child: bookingLineDetail?.child,
        customers: initCustomers()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenCustomer]);

  useEffect(() => {
    form.setFieldsValue({
      adult: bookingLineDetail?.adult,
      child: bookingLineDetail?.child,
      customers: initCustomers()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingLineDetail?.adult, bookingLineDetail?.child, bookingLineDetail?.customers]);

  useEffect(() => {
    if (selectedCustomerIdStore) {
      setSelectedCustomerId(selectedCustomerIdStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerIdStore]);

  useEffect(() => {
    if (isIpadMobile) {
      setIsShowUpload(true);
    } else {
      setIsShowUpload(true);
    }
  }, [isIpadMobile]);

  const handleCancel = () => {
    setIsOpenCustomer(false);
    form.resetFields();
  };

  const handleSelectCustomer = (fieldName: number) => {
    setSelectedCustomerId(fieldName);
  };

  const handleAddCustomer = (add: Function) => {
    add(newCustomer());
    setIsImagesScanned('');
    // customersForm asynchronous so -> replace new index by current length
    setSelectedCustomerId(customersForm.length);
  };

  const handleRemoveCustomer = (remove: Function, fieldName: number) => {
    remove(fieldName);
    if (fieldName !== 0 && fieldName === customersForm.length - 1) {
      setSelectedCustomerId(fieldName - 1);
    }
  };

  const newCustomer = () => {
    const nanoId = nanoid();
    return {
      id: nanoId,
      fakeId: nanoId,
      name: 'Guest',
      gender: SEX.MALE,
      photos: []
    };
  };

  const initCustomers = () => {
    return bookingLineDetail?.customers && bookingLineDetail?.customers.length > 0
      ? bookingLineDetail?.customers.map(customer =>
          trimTruthyValues({
            profile_id: customer.id,
            guest: customer.guest,
            main_guest: customer.main_guest,
            is_child: customer.is_child,
            name: customer.name,
            gender: customer.gender,
            phone: customer.phone,
            email: customer.email,
            dob: customer.dob ? dayjs(customer.dob) : undefined,
            identification: customer.identification,
            country_id: customer.country?.id,
            state_id: customer.state?.id,
            street: customer.street,
            contact_address_complete: customer.contact_address_complete,
            comment: customer.comment,
            attachments: customer.attachments,
            remove_images: []
          })
        )
      : [newCustomer()];
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await createOrUpdateCustomer(values);
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.KEY_ROOM,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail?.booking_line_id
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      setIsImagesScanned('');
      notification.success({
        message: 'Cập nhật thành công'
      });
    } catch (err: any) {
      console.log('aaaa', err);
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title="Sửa khách"
      className="modal-customer"
      centered
      width={isShowUpload ? 1200 : 800}
      open={isOpenCustomer}
      confirmLoading={isPending}
      okText="Lưu"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        form={form}
        name="customer-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          adult: bookingLineDetail?.adult,
          child: bookingLineDetail?.child,
          customers: initCustomers()
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="modal-customer-header">
          <p className="m-0 room-id">
            <IconCancelCalendar />
            <span>
              {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
            </span>
          </p>

          <div className="flex items-center" style={{ gap: '8px' }}>
            <Form.Item name="adult" style={{ marginBottom: 0 }}>
              <InputNumber min={0} prefix={<img src={IconUser} alt="adult" />} />
            </Form.Item>
            <Form.Item name="child" style={{ marginBottom: 0 }}>
              <InputNumber min={0} prefix={<img src={IconChildren} alt="child" />} />
            </Form.Item>
          </div>

          <Button
            icon={<CloudUploadOutlined style={{ fontSize: '28px' }} />}
            type="link"
            className={classNames('btn-upload', { active: isShowUpload })}
            onClick={() => setIsShowUpload(prevShow => !prevShow)}
          />
        </div>

        <Form.List name="customers">
          {(fields, { add, remove }) => {
            return (
              <div className="modal-customer-body">
                <div className="modal-customer-body__list">
                  <div className="modal-customer-body__list-inner">
                    {fields.map(field => {
                      const name = form.getFieldValue(['customers', field.name, 'name']);
                      const main_guest = form.getFieldValue([
                        'customers',
                        field.name,
                        'guest',
                        'main_guest'
                      ]);
                      const fakeId = form.getFieldValue(['customers', field.name, 'fakeId']);

                      return (
                        <Button
                          key={field.name}
                          className={classNames('btn-customer', {
                            active: selectedCustomerId === field.name
                          })}
                          onClick={() => handleSelectCustomer(field.name)}
                          type="text"
                        >
                          <IdcardOutlined className="icon-id" />
                          <p
                            className="m-0 truncate"
                            style={{ maxWidth: '150px', marginLeft: '8px' }}
                          >
                            {name} {main_guest ? '*' : ''}
                          </p>
                          <div className="flex items-center ml-auto" style={{ gap: '6px' }}>
                            {!fakeId && <IconRegisterCard className="ml-auto" />}

                            {fields.length > 1 && (
                              <CloseCircleFilled
                                className="btn-remove"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  handleRemoveCustomer(remove, field.name);
                                }}
                              />
                            )}
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                  <Button
                    className="btn-add w-full"
                    onClick={() => handleAddCustomer(add)}
                    type="text"
                    icon={<PlusOutlined />}
                  >
                    Thêm khách
                  </Button>
                </div>

                <CustomerForm
                  name="customers"
                  selectedCustomerId={selectedCustomerId}
                  form={form}
                  isShowUpload={isShowUpload}
                  onFillCustomerDetails={updateFormFields}
                  bookingLineDetail={bookingLineDetail}
                  isBookingForm={location.pathname === PAGES.receptionistGuestWillCheckIn}
                />
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default CustomerModal;
