import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, Col, Form, FormProps, Input, Modal, notification, Row, Select } from 'antd';
import useModal from 'stores/useModal';

import { useMutation } from '@tanstack/react-query';
import { moveTransaction } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import {
  FOLIO_BALANCE_CODE,
  ValueSelectedItemsOfTransactionSearchBooking
} from 'services/api/type/booking.type';
import CashierModal from 'components/modal/transaction-modal/transaction-search-booking';
import 'styles/move-transaction.scss';

function MoveTransaction() {
  const [form] = Form.useForm();

  const { Search } = Input;

  const { setIsOpenTransactionSearchBooking } = useModal();
  const [selectedItems, setSelectedItems] =
    useState<ValueSelectedItemsOfTransactionSearchBooking>();

  const {
    moveTransaction: { transaction_ids, isOpenMoveTransaction, bookingLineId, code, guest_id },
    closeMoveTransaction
  } = useModal();

  const { data: bookingLineDetailForMoveFrom } = useGetBookingDetail(bookingLineId);

  const { data: bookingLineDetailForMoveTo } = useGetBookingDetail(
    selectedItems?.booking_line_id || bookingLineId
  );

  useEffect(() => {
    const guestId = bookingLineDetailForMoveTo?.booking_line_guests.find(
      item => item.id === guest_id || item.id === selectedItems?.id
    )?.id;

    form.setFieldsValue({
      booking_line_id: bookingLineDetailForMoveTo?.booking_line_id,
      guest_id: guestId,
      folio_balance_code: code
    });
    // eslint-disable-next-line
  }, [bookingLineDetailForMoveTo]);

  const { mutateAsync: mutateMoveTransaction, isPending } = useMutation({
    mutationFn: (params: any) =>
      moveTransaction({
        guest_id: params.guest_id,
        transaction_ids: params.transaction_ids,
        folio_balance_code: params.folio_balance_code
      })
  });

  const handleCloseModal = () => {
    closeMoveTransaction();
    form.resetFields();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutateMoveTransaction({
        guest_id: values.guest_id,
        transaction_ids: transaction_ids,
        folio_balance_code: values.folio_balance_code
      });
      closeMoveTransaction();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, guest_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS]
      });
      notification.success({
        message: 'Chuyển giao dịch thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleSearch = () => {
    setIsOpenTransactionSearchBooking(true);
  };

  return (
    <Modal
      title="MOVE TRANSACTION"
      centered
      width={450}
      open={isOpenMoveTransaction}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      className={classNames('modal-change-price')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        autoComplete="off"
        className="move-transaction-form"
      >
        <Card className="move-transaction-card">
          <h3 className="card-title">MOVE FROM</h3>
          <div className="move-transaction-card__body">
            <Row gutter={[8, 0]} className="w-full equal-spacing-row">
              <Col span={24}>
                <Row className="label-span-row" align="middle">
                  <Col span={10} className="label-column">
                    <strong>Guest Name</strong>
                  </Col>
                  <Col span={14} className="span-column">
                    <span className="ant-form-text">
                      {bookingLineDetailForMoveFrom?.booking_line_guests
                        .filter(item => item.id === guest_id)
                        .map(item => item.guest_name)}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="label-span-row" align="middle">
                  <Col span={10} className="label-column">
                    <strong>Reservation</strong>
                  </Col>
                  <Col span={14} className="span-column">
                    <span className="ant-form-text">
                      {bookingLineDetailForMoveFrom?.booking_line_id}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="label-span-row" align="middle">
                  <Col span={10} className="label-column">
                    <strong>Current Page</strong>
                  </Col>
                  <Col span={14} className="span-column">
                    <span className="ant-form-text">{code}</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="label-span-row" align="middle">
                  <Col span={10} className="label-column">
                    <strong>Room</strong>
                  </Col>
                  <Col span={14} className="span-column">
                    <span className="ant-form-text">
                      {bookingLineDetailForMoveFrom?.room_name ||
                        bookingLineDetailForMoveFrom?.room_type_name}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>

        <Card className="move-transaction-card">
          <h3 className="card-title">MOVE TO</h3>
          <div className="move-transaction-card__body">
            <Row gutter={[8, 0]} className="w-full equal-spacing-row">
              <Col span={24} className="label-column">
                <Form.Item
                  label="Reservation"
                  name="booking_line_id"
                  rules={[{ required: true, message: 'Reservation is required' }]}
                  className="form-item reduced-space label-align-right"
                >
                  <Search
                    placeholder="Reservation"
                    readOnly
                    onSearch={handleSearch}
                    className="input-size-equal"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="field-column">
                <Form.Item
                  label="Guest Name"
                  name="guest_id"
                  rules={[{ required: true, message: 'Guest name is required' }]}
                  className="form-item reduced-space label-align-right"
                >
                  <Select className="input-size-equal">
                    {bookingLineDetailForMoveTo?.booking_line_guests.map(item => (
                      <Select.Option key={item.id} value={item.id} className="select-option">
                        {item.guest_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} className="field-column">
                <Form.Item
                  label="Folio Balance"
                  name="folio_balance_code"
                  rules={[{ required: true, message: 'Folio Balance is required' }]}
                  className="form-item reduced-space label-align-right"
                >
                  <Select className="input-size-equal">
                    <Select options={FOLIO_BALANCE_CODE}></Select>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        <CashierModal setSelectedItems={setSelectedItems} />
      </Form>
    </Modal>
  );
}

export default MoveTransaction;
