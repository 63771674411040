import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { BranchesType } from '@/services/api/type/branch.type';

const borderColor = '#000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 28
  },
  total: {
    width: '34%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 4px'
  },
  nlte: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 4px'
  },
  price: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  services: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  serviceFee: {
    width: '12%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  tax: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  amount: {
    width: '12%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  textBold: { fontSize: 10, fontFamily: 'Inter', fontWeight: 600 }
});

interface Props {
  totalPayment: number;
  totalRemainingAmount: number;
  totalSubAmount: number;
  totalPrice: number;
  totalNLTE: string;
  totalFinalService: number;
  totalFinalServiceFee: number;
  totalFinalTax: number;
  currentBranch: BranchesType | undefined;
}

const InvoiceTableFooter = ({
  totalPayment,
  totalRemainingAmount,
  totalSubAmount,
  totalPrice,
  totalNLTE,
  totalFinalService,
  totalFinalServiceFee,
  totalFinalTax,
  currentBranch
}: Props) => {
  const formatNumber = (textNumber: number) => {
    return textNumber.toLocaleString('vn');
  };

  return (
    <View style={styles.row}>
      <View style={styles.total}>
        <Text style={styles.textBold}>Tổng (Total)</Text>
      </View>
      <View style={styles.nlte}>
        <Text style={styles.textBold}>{totalNLTE}</Text>
      </View>
      <View style={styles.price}>
        <Text style={styles.textBold}>{formatNumber(totalSubAmount)}</Text>
      </View>
      <View style={styles.services}>
        <Text style={styles.textBold}>{formatNumber(totalFinalService)}</Text>
      </View>
      <View style={styles.serviceFee}>
        <Text style={styles.textBold}>{formatNumber(totalFinalServiceFee)}</Text>
      </View>
      <View style={styles.tax}>
        <Text style={styles.textBold}>{formatNumber(totalFinalTax)}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={styles.textBold}>{formatNumber(totalPrice)}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={styles.textBold}>
          {formatNumber(
            currentBranch?.is_enable_v2 ? totalPrice - totalRemainingAmount : totalPayment
          )}
        </Text>
      </View>
      <View style={{ ...styles.amount, borderRightWidth: 0 }}>
        <Text style={styles.textBold}>
          {formatNumber(
            currentBranch?.is_enable_v2 ? totalRemainingAmount : totalPrice - totalPayment
          )}
        </Text>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
